.modal
  background-color: rgba(0,0,0,0.5)
  width: 100%
  height: 100%
  position: fixed
  display: flex
  justify-content: center
  align-items: center
  z-index: 99

.content
  padding: 24px
  background-color: var(--card)
  border-radius: 5px 5px
  box-shadow: 0 0 30px rgba(0,0,0,1)
  z-index: 99
  width: calc( 100% - 24px )
  display: flex
  flex-flow: column
  justify-content: center
  align-items: center
  text-align: center
  max-width: 550px
  .message
    margin: 24px 0
    line-height: 1.75
  svg
    width: 36px
    height: 36px
  .actions
    display: flex
    gap: 12px